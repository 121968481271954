export type sponsoredCategoryType = 'tutoriais' | undefined;
export type editorialCategoryType = 'ctup' | 'ctauto' | 'eletro';

type image = {
  src: string;
  blurhash: string;
  height: number;
  width?: number;
};

export type categorySponsorDataItemType = {
  url: string;
  title: string;
  image: {
    mobile: image;
    desktop: image;
  };
};

type categorySponsorDataType = {
  tutoriais: categorySponsorDataItemType[];
};

export const sponsorCategoryData: categorySponsorDataType = {
  tutoriais: [
    {
      url: 'https://www.kaspersky.com.br/premium?reseller=br_eg-mf_acq_ona_dis__onl_b2c_canaltech_ban_______&utm_content=banner%7Cgeneric&utm_source=canaltech&utm_medium=display&utm_term=youtube&utm_campaign=',
      title: 'Ir para o site do Kaspersky!',
      image: {
        mobile: {
          src: 'https://t.ctcdn.com.br/FnVpzZ0gZiMg1_u-Eskkk_76vHo=/i990769.png',
          blurhash: 'LTS6Plt7%Mt7xufQfQay~qofIUof',
          height: 30,
          width: 80,
        },
        desktop: {
          src: 'https://t.ctcdn.com.br/FnVpzZ0gZiMg1_u-Eskkk_76vHo=/i990769.png',
          blurhash: 'LTS6Plt7%Mt7xufQfQay~qofIUof',
          height: 30,
          width: 80,
        },
      },
    },
  ],
};

export const enableSponsoredCategories = ['tutoriais'];

export const sponsoredCategoriesTutorialsUri = ['tutoriais'];

const editorialCategoriesCtupUri = ['hardware', 'games', 'ctup', 'notebook'];

const sponsoredCategoriesTutorialsTitle = ['Tutoriais'];

const sponsoredpublicationType = [ '4' ];

const editorialCategoriesCtupTitle = ['Hardware', 'Games', 'Notebook'];

const editorialCategoriesCtAutoTitle = ['Veículos', 'Carros', 'Mobilidade Urbana', 'Veículos Autônomos'];

export const getSponsoredCategory = (singleCategory: string): sponsoredCategoryType | undefined => {
  if (sponsoredCategoriesTutorialsUri.includes(singleCategory)) return 'tutoriais';
  return undefined;
};

export const getEditorialCategory = singleCategory => {
  if (editorialCategoriesCtupUri.includes(singleCategory)) return 'ctup';
  if (singleCategory === 'eletro') return 'eletro';
  if (singleCategory === 'ctauto') return 'ctauto';
  return undefined;
};

export const isSponsoredOrEditorialToInternal = (mainCategory, categories, publicationType = '1') => {
  if (sponsoredCategoriesTutorialsTitle.includes(mainCategory)) return 'sponsored';

  if (editorialCategoriesCtupTitle.includes(mainCategory)) return 'editorial';
  if (editorialCategoriesCtAutoTitle.includes(mainCategory)) return 'editorial';
  if (mainCategory === 'CT Eletro') return 'editorial';

  if (categories.some(categoria => sponsoredCategoriesTutorialsTitle.includes(categoria))) return 'sponsored';
  if (sponsoredpublicationType.includes(publicationType)) return 'sponsored';

  if (categories.some(categoria => editorialCategoriesCtupTitle.includes(categoria))) return 'editorial';
  if (categories.some(categoria => editorialCategoriesCtAutoTitle.includes(categoria))) return 'editorial';
  if (categories.includes('CT Eletro')) return 'editorial';
  return undefined;
};

export const getSponsoredCategoryOrTypeWithStringToInternal = (categories: string[], mainCategory: string, publicationType = '1') => {
  let category = undefined;
  if (mainCategory && sponsoredCategoriesTutorialsTitle .includes(mainCategory)) category = 'tutoriais';

  if (sponsoredpublicationType.includes(publicationType)) category = 'tutoriais';

  if (categories.some(categoria => sponsoredCategoriesTutorialsTitle.includes(categoria))) category = 'tutoriais';

  if (!enableSponsoredCategories.includes(category)) return undefined;

  return category;
};
export const getEditorialCategoryWithStringToInternal = (categories: string[], mainCategory: string) => {
  let category = undefined;
  if (categories.some(categoria => editorialCategoriesCtupTitle.includes(categoria))) category = 'ctup';
  if (categories.some(categoria => editorialCategoriesCtAutoTitle.includes(categoria))) category = 'ctauto';
  if (categories.includes('CT Eletro')) category = 'eletro';

  if (editorialCategoriesCtupTitle.includes(mainCategory)) category = 'ctup';
  if (editorialCategoriesCtAutoTitle.includes(mainCategory)) category = 'ctauto';
  if (mainCategory === 'CT Eletro') category = 'eletro';
  return category;
};
